import TagManager from "react-gtm-module";
import getEnv from "~/utils/env";
import { PPCCampaignType } from "~/context/CurrentCampaignContext/types";
import { Resource, ServiceState } from "app/types/permissions";
import {
  CampaignSummary,
  Profile,
  UserDataOrganization,
} from "app/types/userData";
import { isBrowser, isServer } from "~/utils/isBrowser";

declare global {
  interface Window {
    pendo: any;
  }
}

export const compact = (obj: Record<string, any>) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([, v]) => v !== null && v !== undefined && v !== "",
    ),
  );

const AUTOBIDDER = "Autobidder";
const CHANNEL = "Channel";
const ONE_BID = "OneBid";
const PRODUCT = "Product";
const SERVICE = "Service";

type User = {
  isLoggedIn: boolean;
  profile?: Profile;
  organizations: UserDataOrganization[];
  permissions?: Record<string, ServiceState>;
  campaignSummary?: CampaignSummary;
  userId: string;
  employeeId?: number;
  enterpriseVendorId: string;
};

type Ga4Param = {
  selectedPPCCampaign: PPCCampaignType | null;
  selectedPPLCampaign: Resource | null;
  user: User;
  visiblePPCCampaigns: Resource[] | [];
  visiblePPLCampaigns: Resource[] | [];
};

declare global {
  interface Window {
    dataLayer?: Record<string, any>[];
    google_tag_manager: any;
  }
}

export function getUserCampaignDetails(
  user: User,
  selectedPPCCampaign: PPCCampaignType | null,
  selectedPPLCampaign: Resource | null,
) {
  return {
    id: user.enterpriseVendorId,
    name: user?.campaignSummary?.name,
    ppcCampaignId: selectedPPCCampaign?.id,
    pplCampaignId: selectedPPLCampaign?.id,
    creationDate: user?.campaignSummary?.creationDate,
    salesChannel: user?.campaignSummary?.salesChannel,
    upgraded: user?.campaignSummary?.isUpgraded,
  };
}

export const ga4Initialize = ({
  selectedPPCCampaign,
  selectedPPLCampaign,
  user,
  visiblePPCCampaigns,
  visiblePPLCampaigns,
}: Ga4Param) => {
  const isInitialized =
    typeof window !== undefined
      ? window?.dataLayer?.find((e) => e["gtm.start"])
      : false;
  if (isInitialized && user?.isLoggedIn) {
    TagManager.dataLayer({
      dataLayer: {
        ...ga4TrackingData({
          user,
          selectedPPCCampaign,
          selectedPPLCampaign,
          visiblePPCCampaigns,
          visiblePPLCampaigns,
        }),
      },
    });
    return;
  }
  const GTM_ID = getEnv().REACT_APP_GTM_ID;
  const GTM_AUTH = getEnv().REACT_APP_GTM_AUTH;
  const GTM_PREVIEW = getEnv().REACT_APP_GTM_PREVIEW;
  // for logout use case
  if (!Boolean(user) && typeof window !== undefined) {
    TagManager.initialize({
      gtmId: GTM_ID,
      auth: GTM_AUTH,
      preview: GTM_PREVIEW,
    });
  }

  if (GTM_ID && GTM_AUTH && user?.isLoggedIn) {
    TagManager.initialize({
      gtmId: GTM_ID,
      auth: GTM_AUTH,
      preview: GTM_PREVIEW,
      dataLayer: ga4TrackingData({
        user,
        selectedPPCCampaign,
        selectedPPLCampaign,
        visiblePPCCampaigns,
        visiblePPLCampaigns,
      }),
    });
  }
};

export const clearGa4DataLayer = () => {
  const GTM =
    typeof window !== undefined ? window.google_tag_manager : undefined;
  const GTM_ID = getEnv().REACT_APP_GTM_ID;

  if (GTM && GTM_ID) GTM[GTM_ID]?.dataLayer.reset();
};

const campaignBiddingType = (campaignSummary?: CampaignSummary) => {
  if (campaignSummary) {
    return campaignSummary?.autobidder
      ? AUTOBIDDER
      : campaignSummary?.oneBid
        ? ONE_BID
        : CHANNEL;
  }
};

const ga4TrackingData = ({
  user,
  selectedPPCCampaign,
  selectedPPLCampaign,
  visiblePPCCampaigns,
  visiblePPLCampaigns,
}: Ga4Param) => ({
  visitor: {
    id: user.userId,
    hasListingsAccess: user.permissions?.listings?.hasAccess,
    hasPpcAccess: user.permissions?.ppc?.hasAccess,
    hasPplAccess: user.permissions?.ppl?.hasAccess,
    hasReviewsAccess: user.permissions?.reviews?.hasAccess,
    isEmployee: !!user.employeeId,
    isMultiPpc: visiblePPCCampaigns.length > 1,
    isMultiPpl: visiblePPLCampaigns.length > 1,
    isOrganizationAdmin: user.organizations.every(
      ({ isAdmin }) => isAdmin === true,
    ),
    organizationIds: user.organizations.map(({ id }) => id),
    ppcBiddingType: campaignBiddingType(user?.campaignSummary),
    ppcCampaignId: selectedPPCCampaign?.id,
    ppcCampaignIds: visiblePPCCampaigns.map(({ id }) => id),
    ppcCampaignUpgraded: selectedPPCCampaign?.isUpgraded,
    ppcCampaignType: user?.campaignSummary?.isServices ? SERVICE : PRODUCT,
    ppcStartDate: user?.campaignSummary?.creationDate,
    pplCampaignId: selectedPPLCampaign?.id,
    pplCampaignIds: visiblePPLCampaigns.map(({ id }) => id),
    userDepartments: user.profile?.departments?.[0],
    userJobLevel: user.profile?.jobLevel,
    userLanguage: user.profile?.language,
  },
  account: getUserCampaignDetails(
    user,
    selectedPPCCampaign,
    selectedPPLCampaign,
  ),
});

export const ga4Tracking = (eventName: string, context = {}) => {
  if (typeof window === undefined) return;

  TagManager.dataLayer({
    dataLayer: { event: eventName, ...context },
  });
};

type PendoParams = {
  selectedPPCCampaign: PPCCampaignType | null;
  selectedPPLCampaign: Resource | null;
  user: User;
  visiblePPCCampaigns: Resource[] | [];
  visiblePPLCampaigns: Resource[] | [];
};

const pendoTrackingData = ({
  user,
  selectedPPCCampaign,
  selectedPPLCampaign,
  visiblePPCCampaigns,
  visiblePPLCampaigns,
}: PendoParams) => ({
  visitor: compact({
    id: user.userId,
    hasListingsAccess: user.permissions?.listings?.hasAccess,
    hasPpcAccess: user.permissions?.ppc?.hasAccess,
    hasPplAccess: user.permissions?.ppl?.hasAccess,
    hasReviewsAccess: user.permissions?.reviews?.hasAccess,
    isEmployee: !!user.employeeId,
    isMultiPpc: visiblePPCCampaigns.length > 1,
    isMultiPpl: visiblePPLCampaigns.length > 1,
    isOrganizationAdmin: user.organizations.every(
      ({ isAdmin }) => isAdmin === true,
    ),
    organizationIds: user.organizations.map(({ id }) => id),
    ppcBiddingType: campaignBiddingType(user?.campaignSummary),
    ppcCampaignId: selectedPPCCampaign?.id,
    ppcCampaignIds: visiblePPCCampaigns.map(({ id }) => id),
    ppcCampaignUpgraded: selectedPPCCampaign?.isUpgraded,
    ppcCampaignType: user?.campaignSummary?.isServices ? SERVICE : PRODUCT,
    ppcStartDate: user?.campaignSummary?.creationDate,
    pplCampaignId: selectedPPLCampaign?.id,
    pplCampaignIds: visiblePPLCampaigns.map(({ id }) => id),
    userDepartments: user.profile?.departments,
    userJobLevel: user.profile?.jobLevel,
    userLanguage: user.profile?.language,
  }),
  account: getUserCampaignDetails(
    user,
    selectedPPCCampaign,
    selectedPPLCampaign,
  ),
});

export const pendoInitialize = ({
  selectedPPCCampaign,
  selectedPPLCampaign,
  user,
  visiblePPCCampaigns,
  visiblePPLCampaigns,
}: PendoParams) => {
  if (getEnv().REACT_APP_ENV !== "production") return;

  const PENDO_API_KEY = getEnv().REACT_APP_PENDO_API_KEY;

  if (PENDO_API_KEY && user?.isLoggedIn && isBrowser()) {
    const pendoOptions = pendoTrackingData({
      user,
      selectedPPCCampaign,
      selectedPPLCampaign,
      visiblePPCCampaigns,
      visiblePPLCampaigns,
    });

    if (isPendoInitialized()) {
      window.pendo?.updateOptions?.(pendoOptions);
    } else {
      window.pendo?.initialize?.(pendoOptions);
    }
  }
};

export const clearPendoDataLayer = () => {
  const PENDO = isBrowser() ? window.pendo : undefined;
  const PENDO_API_KEY = getEnv().REACT_APP_PENDO_API_KEY;

  if (
    PENDO &&
    PENDO_API_KEY &&
    typeof PENDO.flushNow === "function" &&
    isBrowser()
  ) {
    window.pendo.flushNow();
  }
};

export const pendoTracking = (...args: any) => {
  if (isServer() || !window.pendo) return () => {};
  window.pendo.track(...args);
};

const isPendoInitialized = () => {
  try {
    return isBrowser() ? Boolean(window.pendo.getAccountId()) : false;
  } catch {
    return false;
  }
};
